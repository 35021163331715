import React, { useState } from "react";
import { Layout } from "../components/common";
import { useForm } from "react-hook-form";
import { Link } from "gatsby";

const SubscribePage = () => {
    const [submitted, setSubmitted] = useState(false);
    const { register, handleSubmit } = useForm();

    const onSubmit = async (data) => {
        try {
            await fetch(
                "https://send.pageclip.co/C57D0r0wdQf4Vz4PeYeSWc6rhT64ufQO/taurean",
                {
                    method: "POST",
                    mode: "cors",
                    cache: "no-cache",
                    body: JSON.stringify(data),
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                }
            );
            setSubmitted(true);
        } catch (error) {
            setSubmitted(true);
        }
    };

    const showThankYou = (
        <div>
            <p>Thank You!</p>
        </div>
    );

    const showForm = (
        <section className="content-body">
            <form method="post" onSubmit={handleSubmit(onSubmit)}>
                <input
                    type="text"
                    {...register('name', {
                        required: true,
                    })}
                    placeholder="Tauro"
                    style={{
                        color: "black",
                        padding: "14px 20px",
                        borderRadius: "8px",
                    }} />
                <br />
                <input
                    type="email"
                    {...register('email', {
                        required: true,
                    })}
                    placeholder="sub@taurean.online"
                    style={{
                        color: "black",
                        padding: "14px 20px",
                        borderRadius: "8px",
                    }} />
                <br />
                <input
                    type="phone"
                    {...register('phone', {
                        required: false,
                    })}
                    placeholder="17022222222"
                    style={{
                        color: "black",
                        padding: "14px 20px",
                        borderRadius: "8px",
                    }} />
                <br />
                <br />
                <p className="content">
                    By submitting this form, you agree to be contacted by email
                    and/or SMS. Message and data rate may apply. You can
                    unsubscribe at any time by messaging us. For further
                    information, please consult the{" "}
                    <Link to="/policies">Privacy Policy</Link>.
                </p>
                <button
                    type="submit"
                    style={{
                        backgroundColor: "blue",
                        padding: "14px 20px",
                        borderRadius: "8px",
                    }}
                >
                    <span>Send</span>
                </button>
            </form>
        </section>
    );
    return (
        <Layout>
            <div className="container">
                <article
                    className="content"
                    style={{
                        textAlign: `center`,
                        padding: "4em",
                        background: "black",
                        borderRadius: "1em",
                        color: "white",
                    }}
                >
                    <h1 className="content-title" style={{ color: "white" }}>
                        Subscribe to Taurean
                    </h1>
                    <p style={{ color: "white" }}>
                        Stay up to date! Get all the latest & greatest posts
                        delivered straight to your inbox or phone.
                    </p>
                    <div>{submitted ? showThankYou : showForm}</div>
                </article>
            </div>
        </Layout>
    );
};

export default SubscribePage;
